import {createSelector} from 'reselect';


const selectCategoryReducer = (state) => state.categories;

export const selectCategoriesArray = createSelector(
    [selectCategoryReducer],
    (categoriesSlice) => categoriesSlice.categoriesArray
)

export const selectCategoriesMap = createSelector(
    [selectCategoriesArray],
    (categoriesArray) => {
        return categoriesArray.reduce(
            (acc, element) => {
                const {title, items} = element;
                acc[title.toLowerCase()] = items;
                return acc;
            },
            {}
        );
    }
);

export const selectCategoriesIsLoading = createSelector(
    [selectCategoryReducer],
    (categoriesSlice) => {
        return categoriesSlice.isLoading;
    }
)