export const determineCartWithNewItem = (newItem, existingCart) => {
    let elem_exists = false;
    for (const [ref, elem] of existingCart.entries()) {
        if (elem.id === newItem.id) {
            elem_exists = true;
            let modified_elem = elem;
            modified_elem.quantity = modified_elem.quantity += 1;
            return [
                ...existingCart.slice(0, ref),
                modified_elem,
                ...existingCart.slice(ref + 1, existingCart.length + 1),
            ];
        }
    }

    if (!elem_exists) {
        return [...existingCart, {...newItem, quantity: 1}];
    }
};
export const determineCartWithItemRemoved = (id, existingCart) => {
    for (const [ref, elem] of existingCart.entries()) {
        if (elem.id === id) {
            return [
                ...existingCart.slice(0, ref),
                ...existingCart.slice(ref + 1),
            ];
        }
        console.log(`Failure on removing item ${id} from cart. Could not be found`)
    }
};
export const determineWithItemQuantityUpdated = (id, quantity, existingCart) => {
    console.log(`updating item quantities: ${id}, ${quantity}`)
    for (const [ref, elem] of existingCart.entries()) {
        if (elem.id === id) {
            let new_elem = elem;
            new_elem.quantity = quantity;
            return [
                ...existingCart.slice(0, ref),
                new_elem,
                ...existingCart.slice(ref + 1),
            ];
        }
    }
};