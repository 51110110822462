import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {useSelector} from "react-redux";
import {useState} from 'react';
import {BUTTON_TYPE_CLASSES} from '../button/button';
import {PaymentFormContainer, FormContainer, PaymentButton} from "./payment-form.styles";
import {selectCartTotalPrice} from "../../store/cart/cart.selector";
import {selectCurrentUser} from "../../store/user/user.selector";

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const amount = useSelector(selectCartTotalPrice);
    const currentUser = useSelector(selectCurrentUser);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const paymentHandler = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessingPayment(true);
        const res = await fetch('/.netlify/functions/create-payment-intent', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'amount': amount * 100
            }),
        }).then(res => res.json());
        const {client_secret} = res;
        const paymentResult = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: currentUser ? currentUser.displayName : 'Guest',
                }
            }
        });
        if (paymentResult.error) {
            alert(paymentResult.error.message);
        } else {
            if (paymentResult.paymentIntent.status === 'succeeded') {
                alert('Payment successful');
            }
        }
        setIsProcessingPayment(false);
    }

    return (
        <PaymentFormContainer>
            <h2>
                Secure Card Payment
            </h2>
            <FormContainer onSubmit={paymentHandler}>
                <CardElement/>
                <PaymentButton isLoading={isProcessingPayment} buttonType={BUTTON_TYPE_CLASSES.inverted} disabled={isProcessingPayment || amount <= 0}>
                    Pay Now
                </PaymentButton>
            </FormContainer>
        </PaymentFormContainer>
    )
}

export default PaymentForm;
