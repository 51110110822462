import {Routes, Route} from 'react-router-dom';
import CategoriesPreview from "../categories-preview/categories-preview";
import Category from "../category/category";
import {ShopContainer} from "./shop.styles";
import {useEffect} from "react";
import {fetchCategoriesStart} from "../../store/categories/category.action";
import {useDispatch} from "react-redux";

const Shop = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCategoriesStart());
    }, [dispatch])

    return (
        <ShopContainer>
            <Routes>
                <Route index element={<CategoriesPreview/>}/>
                <Route path=':category' element={<Category/>}/>
            </Routes>
        </ShopContainer>
    )
};

export default Shop;
