import {CartItemStyles} from "./cart-item.styles";

const CartItem = ({cartItem}) => {
    const {name, quantity, imageUrl, price} = cartItem;
    return (
        <CartItemStyles>
            <img src={imageUrl} alt={`${name}`}/>
            <div className='item-details'>
                <span className='name'>
                    {name}
                </span>
                <span className='price'>
                    {quantity} x £{price}.00
                </span>
            </div>
        </CartItemStyles>
    )
}

export default CartItem;
