import {useCallback, useEffect} from "react";
import {CheckoutItemStyles} from "./checkout-item.styles";
import {useDispatch, useSelector} from "react-redux";
import {
    removeCartItem,
    updateCartItemQuantity
} from "../../store/cart/cart.action";
import {selectCartItems} from "../../store/cart/cart.selector";

const CheckoutItem = ({cart_item}) => {
    const {id, imageUrl, name, quantity, price} = cart_item;
    const dispatch = useDispatch();
    const cartItems = useSelector(selectCartItems);

    const removeThisItem = useCallback(() => {
        dispatch(removeCartItem(id, cartItems));
    }, [id, cartItems, dispatch]);

    const updateItemQuantity = useCallback((newQuantity) => {
            dispatch(updateCartItemQuantity(id, newQuantity, cartItems));
        }, [id, cartItems, dispatch]
    );


    useEffect(() => {
        if (quantity <= 0) {
            removeThisItem();
        }
    }, [quantity, id, removeThisItem]);

    return <CheckoutItemStyles>
        <div className='image-container'>
            <img className='image-container' src={imageUrl} alt={`${name}`}/>
        </div>
        <span className='name'>
            {name}
        </span>
        <span className='quantity'>
            <div className='arrow' onClick={() => {
                updateItemQuantity(Math.max(quantity - 1));
            }}>
                &#10094;
            </div>
            <span className='value'>{quantity}</span>
            <div className='arrow' onClick={() => {
                updateItemQuantity(quantity + 1);
            }}>
                &#10095;
            </div>
        </span>
        <span className='price'>
            £{parseFloat(price).toFixed(2)}
        </span>
        <div className='remove-button' onClick={() => {
            removeThisItem();
        }}>&#10005;</div>

    </CheckoutItemStyles>
}

export default CheckoutItem;
