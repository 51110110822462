import {ProductCardStyles} from "./product-card.styles";

import Button, {BUTTON_TYPE_CLASSES} from '../button/button'

import {useDispatch, useSelector} from "react-redux";
import {addNewCartItem} from "../../store/cart/cart.action";
import {selectCartItems} from "../../store/cart/cart.selector";

const ProductCard = ({product_element}) => {
    const dispatch = useDispatch();
    const {imageUrl, name, price} = product_element;
    const cartItems = useSelector(selectCartItems);
    const addThisToCart = () => {
        dispatch(addNewCartItem(product_element, cartItems));
    }

    return (
        <ProductCardStyles>
            <img src={imageUrl} alt={`${name}`}/>
            <div className='footer'>
                <span className={'name'}>
                    {name}
                </span>
                <span className={'name'}>
                    {price}
                </span>
            </div>
            <Button buttonType={BUTTON_TYPE_CLASSES.inverted} onClick={addThisToCart}>Add to cart</Button>
        </ProductCardStyles>
    )
}

export default ProductCard;