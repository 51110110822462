import SignUpForm from '../../components/signup/sign-up-form'
import SignInForm from '../../components/sign-in-form/sign-in-form'
import {useEffect} from "react";
import {getRedirectResult} from "firebase/auth";
import {auth, createUserDocumentFromAuth} from "../../utils/firebase/firebase.utils";
import {AuthenticationContainer} from "./authentication.styles";

const Authentication = () => {
    useEffect(() => {
        const internal_function = async() => {
            const response = await getRedirectResult(auth);
            if (response) {
                createUserDocumentFromAuth(response.user);
            }
        };
        internal_function();
    }, []);

    return (
        <AuthenticationContainer>
            <SignInForm/>
            <SignUpForm/>
        </AuthenticationContainer>
    )
}
export default Authentication;
