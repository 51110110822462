import {useNavigate} from 'react-router-dom';
import {DropdownStyles, CartItems, EmptyMessage} from "./dropdown.styles";
import Button, {BUTTON_TYPE_CLASSES} from '../button/button';
import CartItem from '../cart-item/cart-item'
import {useDispatch, useSelector} from "react-redux";
import {selectCartItems} from "../../store/cart/cart.selector";
import {setCartOpen} from "../../store/cart/cart.action";

const Dropdown = ({checkout_path}) => {
    const cartItems = useSelector(selectCartItems);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <DropdownStyles>
            {cartItems.length ? (
                <CartItems>
                    {cartItems.map((item) => <CartItem key={item.id} cartItem={item}></CartItem>)}
                </CartItems>
            ) : (
                <EmptyMessage>You have no items in your cart just yet</EmptyMessage>
            )
            }
            <Button onClick={() => {
                dispatch(setCartOpen(false));
                navigate(checkout_path);
            }} buttonType={BUTTON_TYPE_CLASSES.base}>Go to checkout</Button>
        </DropdownStyles>
    )
};

export default Dropdown;
