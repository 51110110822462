import {Routes, Route} from 'react-router-dom';
import Home from './routes/home/home';
import NavBar from './routes/navigation/navigation';
import Authentication from './routes/authentication/authentication';
import Shop from './routes/shop/shop';
import Checkout from './routes/checkout/checkout';
import {useEffect} from "react";
import {
    createUserDocumentFromAuth,
    onAuthStateChangedListener
} from "./utils/firebase/firebase.utils";

import {setCurrentUser} from './store/user/user.action'
import {useDispatch} from "react-redux";

const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        return onAuthStateChangedListener((user) => {
            if (user) {
                createUserDocumentFromAuth(user);
            }
            dispatch(setCurrentUser(user));
        })
    }, [dispatch]);

    return (
        <Routes>
            <Route path='/' element={<NavBar/>}>
                <Route index element={<Home/>}/>
                <Route path={'shop/*'} element={<Shop/>}/>
                <Route path={'authentication'} element={<Authentication/>}/>
                <Route path={'checkout'} element={<Checkout/>}/>
            </Route>
        </Routes>
    )
}

export default App;
