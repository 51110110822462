import {useState} from 'react';

import FormInput from '../../components/form-input/form-input'
import {SignUpH2Style, SignUpStyles} from "./sign-up-form.styles";
import Button from '../../components/button/button'
import {signUpStart} from '../../store/user/user.action';
import {useDispatch} from "react-redux";

const defaultFormFields = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
}

const SignUpForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const {displayName, email, password, confirmPassword} = formFields;
    const dispatch = useDispatch();

    const handleFormChange = (event) => {
        const {target} = event;
        setFormFields({...formFields, [target.name]: target.value});
    };

    const resetFormFields = () => {
        setFormFields(defaultFormFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(password !== confirmPassword) {
            alert('Passwords do not match! Cannot create account!')
            return
        }
        try {
            dispatch(signUpStart(email, password, displayName));
            resetFormFields();
            alert('Created user!')
        }
        catch (error){
            console.log('Failed to complete the user creation');
            console.log(error)
            alert(`Failed with error code: ${error.code}`);
        }
    }
    return (
        <SignUpStyles>
            <SignUpH2Style>Don't have an account?</SignUpH2Style>
            <form onSubmit={handleSubmit}>

                <FormInput label='Display Name' type='text' id='input-name-box' name='displayName' onChange={handleFormChange}
                           value={displayName} required />
                <FormInput label={'Email Address'} type='email' id='input-email-box' name='email' onChange={handleFormChange} value={email}
                           required/>
                <FormInput label='Password' type='password' id='input-password-box' name='password' onChange={handleFormChange}
                           value={password} required/>
                <FormInput label='Repeat Password' type='password' id='input-password2-box' name='confirmPassword' onChange={handleFormChange}
                           value={confirmPassword} required/>

                <Button type='submit'>Submit</Button>
            </form>
        </SignUpStyles>
    )
}

export default SignUpForm;
