import styled from 'styled-components';

export const ShopContainer = styled.div`
  .products-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    row-gap: 50px;
  }
`
export default ShopContainer