import {CATEGORIES_ACTION_TYPES} from "./category.types";
import {createDispatchAction} from "../../utils/reducer/reducer.utils";

export const fetchCategoriesStart = () => {
    return createDispatchAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_START);
};

export const fetchCategoriesSuccess = (categoriesArray) => {
    return createDispatchAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS, categoriesArray);
};

export const fetchCategoriesFailed = (error) => {
    return createDispatchAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_FAILED, error);
};
