import {useState} from 'react';
import FormInput from '../../components/form-input/form-input';
import Button, {BUTTON_TYPE_CLASSES} from "../button/button";
import {
    googleSignInStart,
    emailSignInStart,
} from '../../store/user/user.action';

import {SignInFormButtonsContainer, SignInFormH2, SignInFormStyles} from "./sign-in-form.styles";
import {useDispatch} from "react-redux";


const defaultValues = {
    email: '',
    password: '',
}

const SignInForm = () => {
    const [formFields, setFormFields] = useState(defaultValues)
    const {email, password} = formFields;
    const dispatch = useDispatch();

    const handleFormChange = (event) => {
        const {target} = event;
        setFormFields({...formFields, [target.name]: target.value})
    }
    const signInWithGooglePopup = async () => {
        dispatch(googleSignInStart())
    };

    const resetFormFields = () => {
        setFormFields(defaultValues)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            dispatch(emailSignInStart(email, password))
            alert('Successfully signed in!')
            resetFormFields();
        } catch (error) {
            if (error.code === 'auth/wrong-password' || 'auth/wrong-password' || 'auth/user-not-found') {
                alert('Incorrect email/password')
            }
        }
    }

    return (
        <SignInFormStyles>
            <SignInFormH2>Sign In With Your Credentials</SignInFormH2>
            <form onSubmit={handleSubmit}>
                <FormInput label='Email' name='email' onChange={handleFormChange} value={email} type='email' required/>
                <FormInput label='Password' name='password' onChange={handleFormChange} value={password} type='password'
                           required/>

                <SignInFormButtonsContainer>
                    <Button type='submit'>
                        Sign In
                    </Button>
                </SignInFormButtonsContainer>
                <SignInFormH2>Sign in with Google</SignInFormH2>
                <div className='buttons-container'>
                    <Button onClick={signInWithGooglePopup} buttonType={BUTTON_TYPE_CLASSES.google} type='button'>
                        Google Popup
                    </Button>
                    {/*<Button onClick={signInWithGoogleRedirectButton} buttonType={BUTTON_TYPE_CLASSES.google}*/}
                    {/*        type='button'>*/}
                    {/*    Google Redirect*/}
                    {/*</Button>*/}
                </div>
            </form>
        </SignInFormStyles>
    );
}

export default SignInForm;
