import {Group, FormLabel, Input} from "./form-input.styles";

const FormInput = ({label, name, ...otherProps}) => {
    return (
        <Group>
            <Input name={name} {...otherProps}/>
            <FormLabel htmlFor={name} shrink={otherProps.value.length}>{label}</FormLabel>
        </Group>
    )
};

export default FormInput;
