import styled from 'styled-components';

export const CategoryPreviewStyles = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .title {
    font-size: 38px;
    margin-bottom: 25px;
    cursor: pointer;
    text-align: center;
  }

  .preview {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
  }
`