import {createDispatchAction} from "../../utils/reducer/reducer.utils";
import {CART_ACTION_TYPES} from "./cart.types";
import {
    determineCartWithItemRemoved,
    determineCartWithNewItem,
    determineWithItemQuantityUpdated
} from "./cart.action.helpers";


export const addNewCartItem = (newItem, existingCart) => {
    const newCartItems = determineCartWithNewItem(newItem, existingCart);
    return createDispatchAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
}


export const removeCartItem = (id, existingCart) => {
    const newCartItems = determineCartWithItemRemoved(id, existingCart);
    return createDispatchAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
}


export const updateCartItemQuantity = (id, quantity, existingCart) => {
    const newCartItems = determineWithItemQuantityUpdated(id, quantity, existingCart);
    return createDispatchAction(CART_ACTION_TYPES.SET_CART_ITEMS, newCartItems);
}


export const setCartItems = (cartItems) => {
    return createDispatchAction(CART_ACTION_TYPES.SET_CART_ITEMS, cartItems);
};

export const setCartOpen = (cartOpen) => {
    return createDispatchAction(CART_ACTION_TYPES.SET_CART_OPEN, cartOpen);
};
