import CheckoutItem from "../../components/checkout-item/checkout-item";
import {CheckoutContainer} from './checkout.styles'

import {useSelector} from "react-redux";
import {selectCartItems, selectCartTotalPrice} from "../../store/cart/cart.selector";
import PaymentForm from "../../components/payment-form/payment-form";

const Checkout = () => {
    const cartItems = useSelector(selectCartItems)
    const cartTotal = useSelector(selectCartTotalPrice);

    return (
        <CheckoutContainer>
            <div className='checkout-header'>
                <div className='header-block'>
                    <span>Product</span>
                </div>
                <div className='header-block'>
                    <span>Description</span>
                </div>
                <div className='header-block'>
                    <span>Quantity</span>
                </div>
                <div className='header-block'>
                    <span>Price</span>
                </div>
                <div className='header-block'>
                    <span>Remove</span>
                </div>
            </div>
            {cartItems.map((item) => {
                return <CheckoutItem key={item.id} cart_item={item}/>
            })}
            <span className='total'>Total: £{parseFloat(cartTotal).toFixed(2)}</span>
            <PaymentForm/>
        </CheckoutContainer>
    )
}

export default Checkout;
