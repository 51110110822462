import {CartStyles, ItemCount, CartIcon} from "./cart.styles";
import {useSelector} from "react-redux";
import {selectCartCount} from "../../store/cart/cart.selector";

const Cart = ({on_click}) => {
    const cartCount = useSelector(selectCartCount);
    return (
        <CartStyles onClick={on_click}>
            <CartIcon/>
            <ItemCount as='span'>
                {cartCount}
            </ItemCount>
        </CartStyles>
    )
}

export default Cart;