import {Fragment} from 'react'
import {Outlet, Link} from "react-router-dom";

import {ReactComponent as CrownLogo} from '../../assets/crown.svg'
import {useDispatch, useSelector} from "react-redux";
import {NavigationContainer} from "./navigation.styles";

import {signOutUser} from "../../utils/firebase/firebase.utils";
import Cart from '../../components/cart/cart';
import Dropdown from '../../components/dropdown/dropdown';
import {selectCurrentUser} from "../../store/user/user.selector";
import {selectCartOpen} from "../../store/cart/cart.selector";
import {setCartOpen} from "../../store/cart/cart.action";

const NavBar = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const cartOpen = useSelector(selectCartOpen);

    const signOutHandler = () => {
        signOutUser();
    };

    const toggleCartOpen = () => {
        dispatch(setCartOpen(!cartOpen));
    };

    return (
        <Fragment>
            <NavigationContainer>
                <div className='logo-container'>
                    <Link className='logo' to='/'>
                        <CrownLogo/>
                    </Link>
                </div>
                <div className='nav-links-container'>
                    <Link className='nav-link' to='/Shop'>
                        Shop
                    </Link>
                    {!currentUser ? (
                            <Link className='nav-link' to='/authentication'>
                                Sign In
                            </Link>
                        ) :
                        (
                            <Link className='nav-link' onClick={signOutHandler} to='#'>
                                Sign Out
                            </Link>
                        )
                    }
                    <Cart on_click={toggleCartOpen}/>
                </div>
                {cartOpen && <Dropdown checkout_path='/checkout'/>}
            </NavigationContainer>
            <Outlet></Outlet>
        </Fragment>
    )
}

export default NavBar;