import {Outlet} from 'react-router-dom';
import Directory from '../../components/directory/directory.js';
import {HomeStyles} from "./home.styles";

const Home = () => {
    return (
        <HomeStyles>
            <Outlet />
            <Directory />)
        </HomeStyles>
    )
}

export default Home;
