import {createSelector} from 'reselect';

const selectCartReducer = (state) => state.cart;

export const selectCartItems = createSelector(
    [selectCartReducer],
    (cartSlice) => cartSlice.cartItems
);

export const selectCartOpen = createSelector(
    [selectCartReducer],
    (cartSlice) => cartSlice.cartOpen
);

export const selectCartCount = createSelector(
    [selectCartItems],
    (cartItemsSlice) => {
        return cartItemsSlice.reduce((acc, elem) => {
            return acc + elem.quantity;
        }, 0)
    }
);

export const selectCartTotalPrice = createSelector(
    [selectCartItems],
    (cartItemsSlice) => {
        return cartItemsSlice.reduce((acc, elem) => {
            return acc + elem.quantity * elem.price;
        }, 0)
    }
);
