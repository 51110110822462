import {CategoryPreviewStyles} from "./category-preview.styles";

import ProductCard from "../product-card/product-card";
import {Link} from "react-router-dom";
const CategoryPreview = ({title, products}) => {
    return (
        <CategoryPreviewStyles>
            <h2>
                <Link to={`/shop/${title}`}><span className={'title'}>{title.toUpperCase()}</span></Link>
            </h2>
            <div className='preview'>
                {
                    products.filter((_, idx) => {
                        return idx < 4;
                    })
                        .map((product) => {
                            return (
                                <ProductCard key={product.id} product_element={product} />
                            )
                        })
                }
            </div>
        </CategoryPreviewStyles>
    )
};
export default CategoryPreview;