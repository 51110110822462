import {CategoryContainer} from "./category.styles";

import {useParams} from "react-router-dom";
import {Fragment} from 'react';

import ProductCard from "../../components/product-card/product-card";
import {useSelector} from "react-redux";
import {selectCategoriesIsLoading, selectCategoriesMap} from "../../store/categories/category.selector";
import Spinner from "../../components/spinner/spinner";

const Category = () => {
    const {category} = useParams();
    const currentCategories = useSelector(selectCategoriesMap);
    const isLoading = useSelector(selectCategoriesIsLoading);

    return (
        <Fragment>
            {
                isLoading || !(category in currentCategories) ? (<Spinner/>) : (
                    <CategoryContainer>
                        {
                            currentCategories[category].map((product) => (
                                    <ProductCard product_element={product} key={product.id}/>
                                )
                            )
                        }
                    </CategoryContainer>
                )
            }
        </Fragment>
    )
};

export default Category;
