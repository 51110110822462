import React from 'react'
import {BackgroundImage, Body, DirectoryItemContainer} from "./directory-item.styles";
import {useNavigate} from "react-router-dom";

const DirectoryItem = ({category_elem}) => {

    const navigate = useNavigate();
    const {title, imageUrl} = category_elem;
    const onNavigateHandler = () => {
        navigate(`/shop/${title.toLowerCase()}`)
    }

    return (
        <DirectoryItemContainer onClick={onNavigateHandler}>
            <BackgroundImage imageUrl={imageUrl}/>
            <Body>
                <h2>
                    {title}
                </h2>
                <p>
                    Shop Now!
                </p>
            </Body>
        </DirectoryItemContainer>
    )
}


export default DirectoryItem