import {CategoriesPreviewStyles} from './categories-preview.styles';

import CategoryPreview from "../../components/category-preview/category-preview";
import {useSelector} from "react-redux";
import {selectCategoriesIsLoading, selectCategoriesMap} from "../../store/categories/category.selector";
import {Fragment} from 'react';
import Spinner from "../../components/spinner/spinner";


const CategoriesPreview = () => {
    const currentCategories = useSelector(selectCategoriesMap);
    const isLoading = useSelector(selectCategoriesIsLoading);
    return (
        <Fragment>
            {
                isLoading ? (<Spinner/>) :
                    (
                        <CategoriesPreviewStyles>
                            {
                                Object.keys(currentCategories).map((title) => {
                                    const products = currentCategories[title];
                                    return <CategoryPreview title={title} products={products} key={title}/>
                                })
                            }
                        </CategoriesPreviewStyles>
                    )
            }
        </Fragment>

    )
};

export default CategoriesPreview;
